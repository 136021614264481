<template>
  <LoadingPage v-if="loaded && loadPrevent" />
  <div v-else>
    <div v-if="!this.paramId" class="d-flex justify-content-between">
      <h5 class="font-weight-light text-uppercase mb-4">
        {{ $t("groupConfigurationView.title") }}
      </h5>
      <div v-if="this.paramId" style="width: 50px">
        <a
          class="a-edit"
          :href="'/notifications/group_configuration/edit/' + this.paramId"
          ><i class="fas fa-edit"></i>{{ $t("edit") }}</a
        >
      </div>
      <div v-else style="width: 50px">
        <a
          class="a-edit"
          :href="
            '/notifications/group_configuration/edit/' + this.$route.params.id
          "
          ><i class="fas fa-edit"></i>{{ $t("edit") }}</a
        >
      </div>
    </div>

    <div>
      <!-- v-if="!this.paramId" -->
      <div>
        {{ $t("groupConfigurationPage.group") }} :
        <b>{{ config.sales_channels_group_name }}</b> (
        <div
          class="d-inline"
          v-for="(sale, index) in config.sales_channels_name"
          :key="index"
        >
          <span
            v-if="index != Object.keys(config.sales_channels_name).length - 1"
          >
            <b>{{ sale }}, </b>
          </span>
          <span v-else>
            <b>{{ sale }}</b>
          </span>
        </div>
        )
      </div>

      <div>
        {{ $t("createGroupConfigurationPage.countries") }} :
        <div
          v-for="(country, index) in config.countries"
          :key="index"
          class="d-inline"
        >
          <span v-if="index != Object.keys(config.countries).length - 1">
            <b>{{ getCountryName(country) }}, </b>
          </span>
          <span v-else>
            <b>{{ getCountryName(country) }}</b>
          </span>
        </div>
      </div>

      <div>
        {{ $t("status") }} :
        <template v-if="config.is_active">
          <span class="badge badge-success">{{
            $t("groupConfigurationPage.active")
          }}</span>
        </template>
        <template v-else>
          <span class="badge badge-danger">{{
            $t("groupConfigurationPage.inactive")
          }}</span>
        </template>
      </div>

      <div>
        {{ $t("groupConfigurationPage.menu") }} :
        <b>{{
          uppercaseFirstLetter(deleteSpecialCharacters(config.menu_name))
        }}</b>
      </div>
    </div>

    <div class="card-body border-white pt-1">
      <div class="d-flex justify-content-center my-1">
        <b-form-group>
          <b-form-radio-group
            id="quote_option"
            :options="display_options"
            v-model="option_selected"
            buttons
            name="quote_option"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <div
      style="width: 90px"
      class="button-seller-sales-channel"
      v-on:click="seller_visibility = !seller_visibility"
      v-if="seller_visibility == false"
    >
      <i class="fas fa-plus"></i>
      {{ $t("createGroupConfigurationPage.showSeller") }}
    </div>
    <div
      class="button-seller-sales-channel"
      v-on:click="sales_channels_visibility = !sales_channels_visibility"
      v-if="sales_channels_visibility == false"
    >
      <i class="fas fa-plus"></i>
      {{ $t("createGroupConfigurationPage.showSalesChannels") }}
    </div>

    <table class="table table-hover table-bordered">
      <thead>
        <tr>
          <th scope="col" colspan="1"></th>
          <th scope="col" colspan="3">
            {{ $t("createGroupConfigurationPage.customer") }}Customer
          </th>
          <th scope="col" colspan="2" v-if="seller_visibility">
            {{ $t("seller") }} (
            <a href="#" v-on:click="seller_visibility = !seller_visibility">{{
              $t("createGroupConfigurationPage.hide")
            }}</a>
            )
          </th>
          <th scope="col" colspan="2" v-if="sales_channels_visibility">
            {{ $t("salesChannel") }} (
            <a
              href="#"
              v-on:click="
                sales_channels_visibility = !sales_channels_visibility
              "
              >{{ $t("createGroupConfigurationPage.hide") }}</a
            >
            )
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="font-weight-bold">
          <td class="text-left">
            {{ $t("createGroupConfigurationPage.checkpoints") }}
          </td>
          <td>{{ $t("whatsapp") }}</td>
          <td>{{ $t("email") }}</td>
          <td>{{ $t("sms") }}</td>
          <td v-if="seller_visibility">{{ $t("email") }}</td>
          <td v-if="seller_visibility">{{ $t("settingsPage.webhook") }}</td>
          <td v-if="sales_channels_visibility">{{ $t("email") }}</td>
          <td v-if="sales_channels_visibility">
            {{ $t("settingsPage.webhook") }}
          </td>
        </tr>
        <tr
          v-for="(checkpoint, index) in displayByOrderGivenByPierre()"
          :key="index"
        >
          <template
            v-if="
              option_selected == 'MACRO' &&
              checkpointType(checkpoint.id) == option_selected
            "
          >
            <td class="text-left">
              <b>{{ checkIdCheckpoint(checkpoint.id) }}</b>
            </td>

            <td
              class="text-success font-weight-bold"
              v-if="checkpoint.whats_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td class="text-danger font-weight-bold" v-else>{{ $t("no") }}</td>

            <td
              class="text-success font-weight-bold"
              v-if="checkpoint.mail_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td class="text-danger font-weight-bold" v-else>{{ $t("no") }}</td>

            <td
              class="text-success font-weight-bold"
              v-if="checkpoint.sms_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td class="text-danger font-weight-bold" v-else>{{ $t("no") }}</td>

            <td v-if="seller_visibility">
              <template v-if="checkpoint.mail_seller">
                <b class="text-success">{{ $t("yes") }}</b>
              </template>
              <template v-else>
                <b class="text-danger">{{ $t("no") }}</b>
              </template>
            </td>

            <td v-if="seller_visibility">
              <template v-if="checkpoint.webhook_seller">
                <b class="text-success">{{ $t("yes") }}</b>
              </template>
              <template v-else>
                <b class="text-danger">{{ $t("no") }}</b>
              </template>
            </td>

            <td v-if="sales_channels_visibility">
              <template v-if="checkpoint.mail_schannel">
                <b class="text-success">{{ $t("yes") }}</b>
              </template>
              <template v-else>
                <b class="text-danger">{{ $t("no") }}</b>
              </template>
            </td>

            <td v-if="sales_channels_visibility">
              <template v-if="checkpoint.webhook_schannel">
                <b class="text-success">{{ $t("yes") }}</b>
              </template>
              <template v-else>
                <b class="text-danger">{{ $t("no") }}</b>
              </template>
            </td>
          </template>
          <template v-if="option_selected == 'MICRO'">
            <td class="text-left">
              <b>{{ checkIdCheckpoint(checkpoint.id) }}</b>
              {{ getMicroTitle(checkpoint.id) }}
            </td>
            <td
              class="text-success font-weight-bold"
              v-if="checkpoint.whats_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td class="text-danger font-weight-bold" v-else>{{ $t("no") }}</td>

            <td
              class="text-success font-weight-bold"
              v-if="checkpoint.mail_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td class="text-danger font-weight-bold" v-else>{{ $t("no") }}</td>

            <td
              class="text-success font-weight-bold"
              v-if="checkpoint.sms_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td class="text-danger font-weight-bold" v-else>{{ $t("no") }}</td>

            <td v-if="seller_visibility">
              <template v-if="checkpoint.mail_seller">
                <b class="text-success">{{ $t("yes") }}</b>
              </template>
              <template v-else>
                <b class="text-danger">{{ $t("no") }}</b>
              </template>
            </td>

            <td v-if="seller_visibility">
              <template v-if="checkpoint.webhook_seller">
                <b class="text-success">{{ $t("yes") }}</b>
              </template>
              <template v-else>
                <b class="text-danger">{{ $t("no") }}</b>
              </template>
            </td>

            <td v-if="sales_channels_visibility">
              <template v-if="checkpoint.mail_schannel">
                <b class="text-success">{{ $t("yes") }}</b>
              </template>
              <template v-else>
                <b class="text-danger">{{ $t("no") }}</b>
              </template>
            </td>

            <td v-if="sales_channels_visibility">
              <template v-if="checkpoint.webhook_schannel">
                <b class="text-success">{{ $t("yes") }}</b>
              </template>
              <template v-else>
                <b class="text-danger">{{ $t("no") }}</b>
              </template>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import GroupConfigurationServices from "../../services/GroupsConfigurationService";
import CheckpointService from "../../services/CheckpointService";
import CountryService from "../../services/CountryService";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const groupConfigurationService = new GroupConfigurationServices();
const checkpointService = new CheckpointService();
const countryService = new CountryService();

export default {
  name: "GroupConfigurationView",
  components: { LoadingPage },
  props: {
    paramId: {
      required: false,
    },
    loadPrevent: {
      default: true,
      required: false,
    },
  },
  data: () => {
    return {
      loaded: true,
      option_selected: "MACRO",
      config: "",
      checkpoints: "",
      seller_visibility: false,
      sales_channels_visibility: false,
      countries: [],
    };
  },
  beforeMount() {
    countryService.getCountries().then((response) => {
      this.countries = response.data.data;
      checkpointService.getCheckpoints().then((response) => {
        this.checkpoints = response.data.data;
        /* if(this.paramId) {
                            groupConfigurationService.getConfig(this.paramId).then(response => {
                                this.config=response.data.data;
                                this.loaded=false;
                                return
                            })
                        } */
        if (this.loadPrevent) {
          groupConfigurationService
            .getConfig(this.$route.params.id)
            .then((response) => {
              this.config = response.data.data;
              this.loaded = false;
            });
        }
      });
    });
  },
  methods: {
    displayByOrderGivenByPierre() {
      let orders = [
        "NEW",
        "ORDER INFORMATION RECEIVED",
        "READY TO SHIP",
        "PICKUP",
        "EXCEPTION IN PICKUP",
        "SHIPPED",
        "IN TRANSIT",
        "OUT_FOR_DELIVERY",
        "FAILED_ATTEMPT",
        "DELIVERED",
        /*"PARTIAL DELIVERY",*/ "EXCEPTION",
        /*"PARTIAL DELIVERY FAILED",*/ "EXPIRED",
        "PENDING",
        "REVERSE",
      ];
      let newOrder = [];
      orders.forEach((order) => {
        this.config.menu_checkpoints.forEach((menu_checkpoint) => {
          this.checkpoints.forEach((checkpoint) => {
            if (
              checkpoint.name.includes(order) &&
              menu_checkpoint.id == checkpoint.id
            ) {
              newOrder.push(menu_checkpoint);
            }
          });
        });
      });

      /* CODIGO ANTIGO
                for(let i=0 ; i<order.length; i++){
                    for(let j=0 ; j<this.config.menu_checkpoints.length; j++){
                        for(let k=0 ; k<this.checkpoints.length; k++){
                            if(order[i]===this.checkpoints[k].name && (this.config.menu_checkpoints[j].id===this.checkpoints[k].id)){
                                newOrder.push(this.config.menu_checkpoints[j]);
                            }
                        }
                    }    
                }  
                */
      return newOrder;
    },
    checkIdCheckpoint(cp) {
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (cp === this.checkpoints[i].id) return this.checkpoints[i].name;
      }
    },
    checkpointType(cp) {
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (cp === this.checkpoints[i].id) return this.checkpoints[i].type;
      }
    },
    getMicroTitle(cp) {
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (
          cp === this.checkpoints[i].id &&
          this.checkpoints[i].type === "MICRO"
        )
          return this.checkpoints[i].title;
      }
    },
    getCountryName(country) {
      for (let i = 0; i < this.countries.length; i++) {
        if (country === this.countries[i].alpha2_code) {
          return this.countries[i].name;
        }
      }
      return country;
    },

    deleteSpecialCharacters(ch) {
      if (ch) {
        return ch.replace(/[^a-zA-Z ]/g, " ");
      }
      return " ";
    },

    uppercaseFirstLetter(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
  computed: {
    display_options() {
      return [
        { text: this.$t("macro"), value: "MACRO" },
        { text: this.$t("micro"), value: "MICRO" },
      ];
    },
  },
  watch: {
    paramId(val) {
      if (val) {
        groupConfigurationService.getConfig(val).then((response) => {
          this.config = response.data.data;
          return;
        });
      }
    },
  },
};
</script>

<style lang="scss">
td {
  text-align: center;
  vertical-align: middle;
}

.button-seller-sales-channel {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 15px;
  margin-right: 20px;
}

.button-seller-sales-channel:hover {
  font-weight: bold;
  color: var(--lightBlue);
}

.a-edit {
  font-weight: 300;
  font-size: 14px;
  color: var(--primary);
}

.a-edit i {
  font-size: 16px;
}

.a-edit:link {
  text-decoration: none;
}

.a-edit:hover {
  text-decoration: none;
  font-weight: bold;
  color: var(--lightBlue);
}
</style>
