import HttpService from "./HttpService";

export default class GroupsConfigurationService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get Configs
   *
   *
   * @returns {Promise}
   */
  getConfigs() {
    return this.httpService.get("/v1/sales_channels_configurations");
  }

  /**
   * Delete Config
   *
   *
   * @param {*} id
   * @returns {Promise}
   */
  deleteConfiguration(id) {
    return this.httpService.delete("/v1/sales_channels_configurations/" + id);
  }

  /**
   * Get Config
   *
   *
   * @param {*} id
   * @returns {Promise}
   */
  getConfig(id) {
    return this.httpService.get("/v1/sales_channels_configurations/" + id);
  }

  /**
   * Add group
   *
   *
   * @param {*} data
   * @returns {Promise}
   */
  addConfig(data) {
    return this.httpService.post("/v1/sales_channels_configurations", data);
  }

  /**
   * Update group
   *
   *
   * @param {*} id
   * @param {*} data
   * @returns {Promise}
   */
  updateConfig(id, data) {
    return this.httpService.put(
      "/v1/sales_channels_configurations/" + id,
      data
    );
  }
}
