<template>
  <LoadingPage v-if="loaded" />
  <div v-else>
    <h5 class="font-weight-light text-uppercase mb-4">
      {{ $t("createGroupConfigurationPage.title") }}
    </h5>
    <div class="card-body border-white pt-1">
      <div class="d-flex justify-content-center my-1 mt-4 pt-4">
        <b-form-group>
          <b-form-radio-group
            id="display_option"
            :options="display_options"
            v-model="option_selected"
            buttons
            name="display_option"
          ></b-form-radio-group>
        </b-form-group>
      </div>
    </div>

    <div
      class="card-body d-flex align-items-center justify-content-between bg-light mb-4"
    >
      <div class="d-flex align-items-center">
        <div class="row" v-if="option_selected == 'new_menu'">
          <div class="form-group mr-4 pl-3">
            <label>{{ $t("groupConfigurationPage.group") }}</label>
            <select
              class="form-control"
              id="groups"
              v-model="menu.sales_channels_group_id"
            >
              <option value="">{{ $t("orderStatus.selectOption") }}</option>
              <option
                v-for="(group, index) in groups"
                :key="index"
                :value="group.id"
              >
                {{ group.name }}
              </option>
            </select>
          </div>

          <div class="form-group mr-4">
            <label>{{ $t("status") }}</label>
            <select class="form-control" id="status" v-model="menu.is_active">
              <option value="">{{ $t("orderStatus.selectOption") }}</option>
              <option value="true">
                {{ $t("groupConfigurationPage.active") }}
              </option>
              <option value="false">
                {{ $t("groupConfigurationPage.inactive") }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group mr-4" v-else>
          <label>{{ $t("orderStatus.selectOption") }}</label>
          <select class="form-control" id="menus" v-model="old_menu_config">
            <!-- <option value="">Select menu</option> -->
            <option
              v-for="(menu, index) in menus"
              :key="index"
              :value="menu.id"
            >
              {{ menu.sales_channels_group_name }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="option_selected == 'new_menu'">
        <button
          v-on:click="initializeCountries()"
          class="button-light"
          data-toggle="modal"
          data-target="#countries"
        >
          <i class="fas fa-globe-americas"></i>
          {{ $t("createGroupConfigurationPage.countries") }}
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <div>
        <div
          style="width: 90px"
          class="button-seller-sales-channel"
          v-on:click="seller_visibility = !seller_visibility"
          v-if="seller_visibility == false && option_selected == 'new_menu'"
        >
          <!-- v-if="seller_visibility==false && option_selected=='new_menu'" --><i
            class="fas fa-plus"
          ></i>
          {{ $t("createGroupConfigurationPage.showSeller") }}
        </div>
        <div
          class="button-seller-sales-channel"
          v-on:click="sales_channels_visibility = !sales_channels_visibility"
          v-if="
            sales_channels_visibility == false && option_selected == 'new_menu'
          "
        >
          <!-- v-if="sales_channels_visibility==false && option_selected=='new_menu'" --><i
            class="fas fa-plus"
          ></i>
          {{ $t("createGroupConfigurationPage.showSalesChannels") }}
        </div>
      </div>
      <div v-if="option_selected == 'new_menu'">
        <!-- v-if="option_selected=='new_menu'" -->
        <div
          class="button-seller-sales-channel"
          v-if="option_selected_macro_micro == 'MACRO'"
          v-on:click="option_selected_macro_micro = 'MICRO'"
        >
          <i class="fas fa-exchange-alt"></i> {{ $t("showMicro") }}
        </div>
        <div
          class="button-seller-sales-channel"
          v-else
          v-on:click="option_selected_macro_micro = 'MACRO'"
        >
          <i class="fas fa-exchange-alt"></i> {{ $t("showMacro") }}
        </div>
      </div>
    </div>

    <table
      class="table table-hover table-bordered"
      v-if="option_selected == 'new_menu'"
    >
      <!-- v-if="option_selected=='new_menu'" -->
      <thead>
        <tr>
          <th scope="col" colspan="1"></th>
          <th scope="col" colspan="3">
            {{ $t("createGroupConfigurationPage.customer") }}
          </th>
          <th scope="col" colspan="2" v-if="seller_visibility">
            {{ $t("seller") }} (
            <a href="#" v-on:click="seller_visibility = !seller_visibility">{{
              $t("createGroupConfigurationPage.hide")
            }}</a>
            )
          </th>
          <th scope="col" colspan="2" v-if="sales_channels_visibility">
            {{ $t("salesChannel") }} (
            <a
              href="#"
              v-on:click="
                sales_channels_visibility = !sales_channels_visibility
              "
              >{{ $t("createGroupConfigurationPage.hide") }}</a
            >
            )
          </th>
        </tr>
      </thead>
      <tbody>
        <tr style="font-weight: bold">
          <td class="text-left">
            {{ $t("createGroupConfigurationPage.checkpoints") }}
          </td>
          <td>{{ $t("whatsapp") }}</td>
          <td>{{ $t("email") }}</td>
          <td>{{ $t("sms") }}</td>
          <td v-if="seller_visibility">{{ $t("email") }}</td>
          <td v-if="seller_visibility">{{ $t("settingsPage.webhook") }}</td>
          <td v-if="sales_channels_visibility">{{ $t("email") }}</td>
          <td v-if="sales_channels_visibility">
            {{ $t("settingsPage.webhook") }}
          </td>
        </tr>
        <tr v-for="(checkpoint, index) in this.checkpoints" :key="index">
          <template
            v-if="
              option_selected_macro_micro == 'MACRO' &&
              checkpointType(checkpoint.id) == option_selected_macro_micro
            "
          >
            <td class="text-left">
              <b>{{ checkIdCheckpoint(checkpoint.id) }}</b>
            </td>

            <td
              v-on:click="changeValueMenu(index, 'whats_customer')"
              class="text-success font-weight-bold"
              v-if="menu_config[index].whats_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'whats_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <td
              v-on:click="changeValueMenu(index, 'mail_customer')"
              class="text-success font-weight-bold"
              v-if="menu_config[index].mail_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'mail_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <td
              v-on:click="changeValueMenu(index, 'sms_customer')"
              class="text-success font-weight-bold"
              v-if="menu_config[index].sms_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'sms_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <template v-if="seller_visibility">
              <td
                v-on:click="changeValueMenu(index, 'mail_seller')"
                class="text-success font-weight-bold"
                v-if="menu_config[index].mail_seller == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'mail_seller')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="seller_visibility">
              <td
                v-on:click="changeValueMenu(index, 'webhook_seller')"
                class="text-success font-weight-bold"
                v-if="menu_config[index].webhook_seller == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'webhook_seller')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="sales_channels_visibility">
              <td
                v-on:click="changeValueMenu(index, 'mail_schannel')"
                class="text-success font-weight-bold"
                v-if="menu_config[index].mail_schannel == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'mail_schannel')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="sales_channels_visibility">
              <td
                v-on:click="changeValueMenu(index, 'webhook_schannel')"
                class="text-success font-weight-bold"
                v-if="menu_config[index].webhook_schannel == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'webhook_schannel')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>
          </template>
          <template v-if="option_selected_macro_micro == 'MICRO'">
            <td class="text-left">
              <b>{{ checkIdCheckpoint(checkpoint.id) }}</b>
              {{ getMicroTitle(checkpoint.id) }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'whats_customer')"
              class="text-success font-weight-bold"
              v-if="menu_config[index].whats_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'whats_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <td
              v-on:click="changeValueMenu(index, 'mail_customer')"
              class="text-success font-weight-bold"
              v-if="menu_config[index].mail_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'mail_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <td
              v-on:click="changeValueMenu(index, 'sms_customer')"
              class="text-success font-weight-bold"
              v-if="menu_config[index].sms_customer == true"
            >
              {{ $t("yes") }}
            </td>
            <td
              v-on:click="changeValueMenu(index, 'sms_customer')"
              class="text-danger font-weight-bold"
              v-else
            >
              {{ $t("no") }}
            </td>

            <template v-if="seller_visibility">
              <td
                v-on:click="changeValueMenu(index, 'mail_seller')"
                class="text-success font-weight-bold"
                v-if="menu_config[index].mail_seller == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'mail_seller')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="seller_visibility">
              <td
                v-on:click="changeValueMenu(index, 'webhook_seller')"
                class="text-success font-weight-bold"
                v-if="menu_config[index].webhook_seller == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'webhook_seller')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="sales_channels_visibility">
              <td
                v-on:click="changeValueMenu(index, 'mail_schannel')"
                class="text-success font-weight-bold"
                v-if="menu_config[index].mail_schannel == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'mail_schannel')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>

            <template v-if="sales_channels_visibility">
              <td
                v-on:click="changeValueMenu(index, 'webhook_schannel')"
                class="text-success font-weight-bold"
                v-if="menu_config[index].webhook_schannel == true"
              >
                {{ $t("yes") }}
              </td>
              <td
                v-on:click="changeValueMenu(index, 'webhook_schannel')"
                class="text-danger font-weight-bold"
                v-else
              >
                {{ $t("no") }}
              </td>
            </template>
          </template>
        </tr>
      </tbody>
    </table>

    <group-configuration-view v-else :paramId="menuId" :loadPrevent="false" />

    <div v-if="option_selected == 'new_menu'" class="mt-4 pb-4 float-right">
      <button type="button" v-on:click="addConfig()" class="button-secondary">
        {{ $t("createGroupConfigurationPage.addConfiguration") }}
      </button>
    </div>

    <div
      class="modal fade"
      id="countries"
      tabindex="-1"
      role="dialog"
      aria-labelledby="countriesModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="countriesModalLabel">
              {{ $t("createGroupConfigurationPage.countries") }}
            </h5>

            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              v-on:click="initializeCountries()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="not-chosen">{{
                    $t("orderStatus.selectOption")
                  }}</label>
                  <select
                    multiple
                    class="form-control"
                    id="not-chosen"
                    v-model="temp_chosen_countries"
                  >
                    <option
                      v-for="(country, index) in countries"
                      :key="index"
                      :value="index"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <button
                  class="float-right button-edit-config"
                  v-on:click="addfromselectionToChosenCountries()"
                >
                  <i class="fas fa-angle-double-right"></i>
                </button>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label for="chosen">{{
                    $t("createGroupConfigurationPage.chosenCountries")
                  }}</label>
                  <select
                    multiple
                    class="form-control"
                    id="chosen"
                    v-model="temp_countries"
                  >
                    <option
                      v-for="(country, index) in chosen_countries"
                      :key="index"
                      :value="index"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
                <button
                  class="button-edit-config"
                  v-on:click="removeSelectionToCountries()"
                >
                  <i class="fas fa-angle-double-left"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="button-light"
              data-dismiss="modal"
              v-on:click="initializeCountries()"
            >
              {{ $t("cancel") }}
            </button>
            <button
              type="button"
              class="button-primary"
              data-dismiss="modal"
              v-on:click="saveChangeCountries()"
            >
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckpointService from "../../services/CheckpointService";
/* import MenuConfigurationService from "../../services/MenuConfigurationService"; */
import SalesChannelsManagementService from "../../services/SalesChannelManagementService";
import CountryService from "../../services/CountryService";
import GroupsConfigurationService from "../../services/GroupsConfigurationService";
import GroupConfigurationView from "./GroupConfigurationView.vue";
import LoadingPage from "@/components/loadings/LoadingPage.vue";

const checkpointService = new CheckpointService();
/* const menuConfigurationService= new MenuConfigurationService(); */
const salesChannelManagementService = new SalesChannelsManagementService();
const countryService = new CountryService();
const groupsConfigurationService = new GroupsConfigurationService();

export default {
  name: "CreateGroupConfiguration",
  components: { GroupConfigurationView, LoadingPage },
  data: () => {
    return {
      menuId: "",
      loaded: true,
      menu: {
        use_existing_menu: false,
        sales_channels_group_id: "",
        countries: [],
        is_active: false,
      },
      seller_visibility: false,
      sales_channels_visibility: false,

      option_selected: "new_menu",

      option_selected_macro_micro: "MACRO",

      groups: "",
      checkpoints: "",
      menus: "",
      countries: [],
      chosen_countries: [],
      search_country: "",
      search_chosen_countries: "",
      menu_config: [],
      old_menu_config: "",
      errors: [],
      temp_countries: [],
      temp_chosen_countries: [],
      backup_chosen_countries: [],
    };
  },
  beforeMount() {
    countryService.getCountries().then((response) => {
      //for adding all countries change the BLOCK to this.countries=response.data.data;

      //BLOCK
      let countries = [];
      countries = response.data.data;
      for (let i = 0; i < countries.length; i++) {
        if (countries[i].name == "Brazil") {
          this.countries.push(countries[i]);
        }
      }
      //END BLOCK

      salesChannelManagementService.getGroups().then((response) => {
        this.groups = response.data.data;
        groupsConfigurationService.getConfigs().then((response) => {
          this.menus = response.data.data;
          checkpointService.getCheckpoints().then((response) => {
            this.checkpoints = response.data.data;
            this.displayByOrderGivenByPierre();
            this.loaded = false;
          });
        });
      });

      /* 
                salesChannelManagementService.getGroups().then(response=>{
                    this.groups=response.data.data;
                    menuConfigurationService.getMenus().then(response => {
                        this.menus=response.data.data;
                        checkpointService.getCheckpoints().then(response => {
                            this.checkpoints=response.data.data;
                            this.displayByOrderGivenByPierre();
                            this.loaded=false;
                        })
                    })
                }); 
                */
    });
  },
  methods: {
    addConfig() {
      this.menu.countries = this.chosen_countries;
      if (this.option_selected == "new_menu") {
        this.menu.use_existing_menu = false;
        this.menu.new_menu = this.menu_config;
        this.menu.menu_id ? delete this.menu.menu_id : "";
      }
      for (let i = 0; i < this.menu.countries.length; i++) {
        this.menu.countries[i] = this.menu.countries[i].alpha2_code;
      }
      /* if(this.menu.menu_id) {
                    groupsConfigurationService.updateConfig(this.menu.menu_id ,this.menu).then(()=>{
                        this.$router.push('/notifications/group_configuration');
                    })
                    .catch(err=>{
                        this.errors=err.response.data.messages[0];
                    })
                } else { */
      groupsConfigurationService
        .addConfig(this.menu)
        .then(() => {
          this.$router.push("/notifications/group_configuration").then(() => {
            this.$helpers.toast("Configuration saved", "success", 5000);
          });
        })
        .catch((err) => {
          this.errors = err.response.data.messages[0];
          Object.keys(this.errors).forEach((key) => {
            this.$helpers.toast(this.errors[key], "danger");
          });
        });
      /* } */
    },
    getMicroTitle(cp) {
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (
          cp === this.checkpoints[i].id &&
          this.checkpoints[i].type === "MICRO"
        )
          return this.checkpoints[i].title;
      }
    },
    checkIdCheckpoint(cp) {
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (cp === this.checkpoints[i].id) return this.checkpoints[i].name;
      }
    },
    checkpointType(cp) {
      for (let i = 0; i < this.checkpoints.length; i++) {
        if (cp === this.checkpoints[i].id) return this.checkpoints[i].type;
      }
    },
    changeValueMenu(index, key) {
      this.menu_config[index][key] = !this.menu_config[index][key];
    },

    addToChosenCountries(country) {
      this.chosen_countries.push(this.countries[country]);
      this.countries.splice(country, 1);
    },

    removeFromChosenCountries(country) {
      this.countries.push(this.chosen_countries[country]);
      this.chosen_countries.splice(country, 1);
    },
    /*initializeCountries(){
                for(let i = 0 ; i < this.chosen_countries.length ; i++){
                    this.countries.push(this.chosen_countries[i]);
                    this.chosen_countries.splice(i,1);
                    i--;
                }
            },*/
    initializeCountries() {
      this.chosen_countries = JSON.parse(
        JSON.stringify(this.backup_chosen_countries)
      );
    },
    addfromselectionToChosenCountries() {
      if (this.temp_chosen_countries.length > 0) {
        this.changes = true;
        for (let i = this.temp_chosen_countries.length - 1; i >= 0; i--) {
          this.chosen_countries.push(
            this.countries[this.temp_chosen_countries[i]]
          );
          this.countries.splice(this.temp_chosen_countries[i], 1);
        }
        this.temp_chosen_countries = [];
      }
    },

    removeSelectionToCountries() {
      if (this.temp_countries.length > 0) {
        this.changes = true;
        for (let i = this.temp_countries.length - 1; i >= 0; i--) {
          this.countries.push(this.chosen_countries[this.temp_countries[i]]);
          this.chosen_countries.splice(this.temp_countries[i], 1);
        }
        this.temp_countries = [];
      }
    },

    saveChangeCountries() {
      this.backup_chosen_countries = JSON.parse(
        JSON.stringify(this.chosen_countries)
      );
    },

    displayByOrderGivenByPierre() {
      this.checkpoints.forEach(() => {
        this.menu_config.push({
          id: null,
          whats_customer: false,
          mail_customer: false,
          sms_customer: false,
          mail_seller: false,
          webhook_seller: false,
          mail_schannel: false,
          webhook_schannel: false,
        });
      });
      let order = this.checkpoints
        .map((c) => c.name)
        .filter((item, i, ar) => ar.indexOf(item) === i)
        .sort();

      this.checkpoints.sort(function (a, b) {
        return a.id - b.id;
      });

      let newOrder = [];
      let newOrderIds = [];
      for (let i = 0; i < order.length; i++) {
        for (let j = 0; j < this.checkpoints.length; j++) {
          if (
            this.checkpoints[j].name.startsWith(order[i]) &&
            !newOrderIds.includes(this.checkpoints[j].id)
          ) {
            newOrder.push(this.checkpoints[j]);
            newOrderIds.push(this.checkpoints[j].id);
          }
        }
      }
      this.checkpoints = newOrder;
      this.menu_config = Array(this.checkpoints.length).fill({});
      for (let j = 0; j < this.checkpoints.length; j++) {
        this.menu_config[j].id = this.checkpoints[j].id;
      }
    },
  },
  computed: {
    country_search_list() {
      return this.countries.filter((country) => {
        return country.name
          .toLowerCase()
          .includes(this.search_country.toLowerCase());
      });
    },

    chosen_country_search_list() {
      return this.chosen_countries.filter((country) => {
        return country.name
          .toLowerCase()
          .includes(this.search_chosen_countries.toLowerCase());
      });
    },

    display_options() {
      return [
        {
          text: this.$t("createGroupConfigurationPage.newMenu"),
          value: "new_menu",
        },
        {
          text: this.$t("createGroupConfigurationPage.existingMenu"),
          value: "old_menu",
        },
      ];
    },
  },
  watch: {
    old_menu_config(val) {
      this.menuId = val;
    },
  },
};
</script>

<style lang="scss">
td {
  text-align: center;
  vertical-align: middle;
}

.content-table td:hover {
  background-color: var(--lightGray);
  cursor: pointer;
}
.content-table td:first-child {
  font-weight: 550;
  cursor: text;
}

.title-checkpoint {
  font-weight: 500;
}
</style>
