import HttpService from "./HttpService";

export default class CheckpointService {
  constructor() {
    this.httpService = HttpService;
  }

  /**
   * Get carriers
   *
   * @returns {Promise}
   */
  getCheckpoints() {
    return this.httpService.get("/v1/checkpoints");
  }

  getVolumeCheckpoints(id = null) {
    return this.httpService.get("/v1/orders/" + id + "/volume-checkpoints");
  }

  getMacros() {
    return this.httpService.get("/v1/checkpoints-macros");
  }

  addCheckpointToVolume(volumeId, params) {
    return this.httpService.post(
      "/v1/volumes/" + volumeId + "/add-checkpoint",
      params
    );
  }
}
